const thousand = (num) => {
  if (num) {
    let num_parts = num.toString().split(",");
    num_parts[0] = num_parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    return num_parts.join(",");
  } else {
    return 0;
  }
};
const thousandMask = (num) => {
  if (num) {
    let num_parts = num.toString().split(",");
    num_parts[0] = num_parts[0].replace(/\./g, "");
    num_parts[0] = num_parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    return [num_parts.join(",")];
  } else {
    return [0];
  }
};
const removeThousand = (num) => {
  if (num) {
    let num_parts = num.toString().split(",");
    num_parts[0] = num_parts[0].replace(/\./g, "");
    return num_parts.join(",");
  } else {
    return 0;
  }
};

const today = () => {
  const date = new Date();
  let day = date.getDate();
  let month = date.getMonth() + 1;
  let year = date.getFullYear();
  let currentDate = `${day}/${month}/${year}`;
  return currentDate;
};

const formatDate = (dt, format = "YYYY-MM-DD") => {
  const date = new Date(dt);
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();

  if (format === "DD-MM-YYYY") {
    return `${day < 10 ? "0" + day : day}/${
      month < 10 ? "0" + month : month
    }/${year}`;
  }

  return `${year}-${month < 10 ? "0" + month : month}-${
    day < 10 ? "0" + day : day
  }`;
};

const fileToBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
    reader.readAsDataURL(file);
  });
};

export default {
  thousand,
  thousandMask,
  removeThousand,
  today,
  formatDate,
  fileToBase64,
};
